.o-card {
    $root: &;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    background: $white;
    box-shadow: 0 rem(8) rem(20) $raisin-black007;
    border-radius: rem(8);

    input:checked ~ &_label {
        border-color: $gainsboro;
    }

    &_label {
        display: block;
        width: 100%;
        padding: rem(32) rem(16) rem(16) rem(16);
        border-radius: rem(8);
        border: rem(1) solid transparent;
        transition: all $transition linear;
    }

    &_inputCustom {
        position: absolute;
        width: rem(20);
        height: rem(20);
        border-radius: rem(99);
        border: rem(1) solid $gainsboro;
        top: rem(8);
        right: rem(8);
        transition: all $transition linear;
    }

    &_inputCustom::after {
        content: '';
        position: absolute;
        display: none;
    }

    input:checked ~ &_label > &_inputCustom {
        border: rem(1) solid $la-salle-green;
    }

    input:checked ~ &_label > &_inputCustom::after {
        display: block;
    }

    &_inputCustom::after {
        background: $la-salle-green;
        width: rem(12);
        height: rem(12);
        border-radius: rem(99);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }


    &_item {
        @include adjust-flex(space-between, center);
        margin-top: rem(16);

        &:first-child {
            margin-top: 0;

            .a-text {
                font-weight: 700;
            }
        }
    }
}
