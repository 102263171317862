.m-processTitle {
    @include adjust-flex(space-between);
    padding: rem(12);
    border: 1px solid $gainsboro;
    border-radius: rem(4);
    background-color: $white-smoke2;

    &_wrapTitle {
        @include adjust-flex(space-between);

        &-title {
            min-width: rem(80);
            flex: 1;
            margin-right: rem(12);

            @include mobile-down {
                margin-right: rem(6);
            }
        }
    }
}
