.p-home {
    &_section {
        margin-top: rem(24);
    }

    &_btnGroup {
        margin: rem(-8);
        @include adjust-flex();

        &-item {
            padding: rem(8);
            flex: 1;
        }
    }

    &_mt {
        margin-top: rem(8);
    }

    &_invalid {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include adjust-flex();
    }
}

.popupImage {
    &_quantity {
        margin-top: rem(15);
    }

    &_input {
        margin-top: rem(15);
        margin-bottom: rem(16);
    }

    &_list {
        margin: rem(-6);
        display: flex;
        flex-wrap: wrap;
        position: relative;

        &-item {
            padding: rem(6);
            width: 100%;
            max-width: calc(100% / 4);
        }

        &-img {
            border-radius: rem(4);
            overflow: hidden;
        }

        &-showAll {
            position: absolute;
            right: rem(6);
            top: rem(6);
            bottom: rem(6);
            width: 100%;
            max-width: calc(100% / 4 - 12px);
            background: rgba($black, 0.6);
            border-radius: 4px;
            cursor: pointer;
            @include adjust-flex();
        }
    }

    &_button {
        margin-top: rem(24);
    }

    &_buttonHide {
        max-width: rem(100);
        margin: rem(24) auto 0;
    }

    &_carousel {
        &-item {
            height: 85vh;

            .a-image {
                height: 100%;
            }
        }
    }
}
