.o-table {
    width: 100%;
    border-spacing: 0;

    thead {
        th {
            padding: rem(8);
            background-color: $la-salle-green;
            border: rem(1) solid $anti-flash-white1;
            text-align: center;

            &:first-child {
                text-align: left;
            }
        }
    }

    tbody {
        td {
            padding: rem(8);
            text-align: center;
            border: rem(1) solid $anti-flash-white1;

            &:first-child {
                text-align: left;
            }

            &:only-child {
                text-align: center;
            }
        }
    }
}
