.o-carousel {
    $root: &;
    position: relative;

    &_arrow {
        $arrow: &;
        border-radius: 50%;
        height: rem(28);
        transition: $transition-long ease;
        width: rem(28);
        z-index: 1;

        &::before {
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-size: rem(28);
            content: '';
            display: block;
            height: 100%;
            opacity: 1;
            transition: $transition-long ease;
            width: 100%;
        }

        &.white {
            &:hover {
                &::before {
                    opacity: 0.5;
                }
            }
        }


        &_prev {
            left: rem(16);
  
            &.white {
                &::before {
                    background-image: url('~assets/icons/ic_arrow_circle_prev_white.svg');
                }
            }
        }

        &_next {
            right: rem(16);
  
            &.white {
                &::before {
                    background-image: url('~assets/icons/ic_arrow_circle_next_white.svg');
                }
            }
        }

        &.slick-disabled {
            cursor: no-drop;

            &::before {
                opacity: 0.5;
            }
        }
    }

    .slick-slide {
        outline: none;

        div {
            &:focus {
                outline: 0;
            }
        }
    }
}

