.t-popupTable {
    &_button {
        margin: rem(12) auto 0;
        max-width: rem(160);
    }

    &_note {
        margin: rem(12) auto 0;
    }
}
