.m-signature {
    $root: &;

    position: relative;

    &_placeholder {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        left: 0;
        pointer-events: none;
    }

    &_pad {
        border: 1px solid $anti-flash-white1;
        border-radius: rem(4);
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;

        @include tablet-down {
            max-width: calc(100% - 72px);
        }

        @include mobile-down {
            max-width: calc(100% - 48px);
        }

        &-error {
            border: 1px solid $red-orange;
            background-color: $seashell;
            #{$root}_placeholder {
                .a-text {
                    color: $red-orange;
                }
            }
        }
    }

    &_button {
        margin-top: rem(24);
    }
}
