.o-header {
    &_primary {
        padding: rem(24);
        background: $white-smoke2;
        border-bottom: 1px solid $gainsboro;
        @include adjust-flex();
        position: relative;

        &-icon {
            position: absolute;
            left: rem(24);
            cursor: pointer;
        }
    }

    //     &_secondary {
    //         padding: rem(40) rem(24) rem(20);
    //         background: $white-smoke2;
    //         border-bottom: 1px solid $gainsboro;
    //         display: flex;
    //         align-items: center;

    //         &-profile {
    //             flex: 1;
    //             display: flex;
    //             align-items: center;
    //         }

    //         &-avatar {
    //             width: 100%;
    //             max-width: rem(64);
    //             border-radius: 50%;
    //             overflow: hidden;
    //             margin-right: rem(12);
    //         }

    //         &-subName {
    //             margin: rem(4) 0;
    //         }

    //         &-backToOutlet {
    //             margin-top: rem(12);
    //             @include adjust-flex();
    //             background-color: $gainsboro;
    //             border-radius: rem(4);
    //             padding: rem(4) rem(8);
    //             cursor: pointer;

    //             .a-icon {
    //                 margin-right: rem(4);
    //             }
    //         }
    //     }
}
