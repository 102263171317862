.o-infoCard {

    background: $white-smoke2;
    border-radius: rem(4);
    padding: rem(12);

    &_info {
        
        @include adjust-flex(space-between, center);
        margin-top: rem(8);

        &:first-child {
            margin-top: rem(0);
        }
    }
}
